import React, { Component } from 'react'

import ProthesesPage from '../containers/ProthesesPage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const Protheses = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentProtheses}
    >
      <ProthesesPage />
    </PrimaryLayout>
  )
}

export default Protheses
