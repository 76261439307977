import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Link, Element } from 'react-scroll'
import Img from 'gatsby-image'
import Iframe from 'react-iframe'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { cover } from 'polished'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import ShadowWrapper from '../components/ShadowWrapper'
import Button from '../components/Button'
import Team from '../components/Team'
import Gallery from '../components/Gallery'
import Slider from '../components/Slider'
import Map from '../components/Map'
import {
  defaultState,
  slides,
  draggedSpringOptions,
  trailingSpringOptions,
} from '../components/Slider/data'

import GatsbyLink from '../components/Link'

import pagesInfos from '../config/i18n/pagesInfos'

const offset = 30

const useStyles = makeStyles(theme => ({
  eventHeader: {
    height: '150px',
    padding: theme.spacing(2),
    backgroundPosition: 'top',
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    position: 'relative',
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  eventHeaderCover: {
    ...cover(),
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6,
    zIndex: -1,
  },
  h3: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(6),
  },
  slider: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
  iframe: {
    display: 'block',
    border: 0,
    height: '400px',
    boxShadow:
      '0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6)',
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
    outline: '8px solid ' + theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  imageBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: offset,
    bottom: offset,
    left: offset,
    right: offset,
    //borderRadius: '8px',
    zIndex: -1,

    border: 'solid 8px ' + theme.palette.secondary.main,
  },
  imageWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}))

const ImplantologiePage = ({}) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const [index, setIndex] = React.useState(0)
  const data = useStaticQuery(graphql`
    query {
      bgCabinet: file(
        relativePath: {
          eq: "home/modern-metallic-dentist-tools-and-burnishers-on-a-6ZPKC39.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      protheses1: file(relativePath: { eq: "protheses/protheses1.png" }) {
        childImageSharp {
          fixed(width: 599) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      protheses2: file(relativePath: { eq: "protheses/protheses2.png" }) {
        childImageSharp {
          fixed(width: 599) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      protheses3: file(relativePath: { eq: "protheses/protheses3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      protheses4: file(relativePath: { eq: "protheses/protheses4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet
        title={t('protheses:head.title')}
        meta={[
          {
            name: 'description',
            content: t('protheses:head.description'),
          },
        ]}
      />
      <Element id="accueil" name="accueil">
        <Hero title={t('protheses:title')} variant="secondary"></Hero>
      </Element>

      <Section bg="#fafdfd">
        {t('protheses:section1.paragraphs1', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
      </Section>

      <Section bg="#fff">
        <Grid container justify="space-between">
          <Grid item sm={12} xs={12}>
            <Heading
              title={t('protheses:section2.title')}
              variant="secondary"
            />
            {t('protheses:section2.paragraphs1', {
              returnObjects: true,
            }).map(paragraph => (
              <Typography
                component="p"
                variant="body2"
                paragraph
                key={paragraph}
              >
                {paragraph}
              </Typography>
            ))}
            <ul>
              {t('protheses:section2.list1', {
                returnObjects: true,
              }).map(list => (
                <Typography component="li" variant="body2" key={list}>
                  {list}
                </Typography>
              ))}
            </ul>
            {t('protheses:section2.paragraphs2', {
              returnObjects: true,
            }).map(paragraph => (
              <Typography
                component="p"
                variant="body2"
                paragraph
                key={paragraph}
              >
                {paragraph}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Section>

      <Section bg="#fafdfd">
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <Heading
              title={t('protheses:section3.title')}
              variant="secondary"
            />
            {t('protheses:section3.paragraphs1', {
              returnObjects: true,
            }).map(paragraph => (
              <Typography
                component="p"
                variant="body2"
                paragraph
                key={paragraph}
              >
                {paragraph}
              </Typography>
            ))}

            <ul>
              {t('protheses:section3.list1', {
                returnObjects: true,
              }).map(list => (
                <Typography
                  component="li"
                  variant="body2"
                  key={list}
                  dangerouslySetInnerHTML={{ __html: list }}
                />
              ))}
            </ul>
          </Grid>
          <Grid item sm={12} xs={12} container justify="center">
            <ShadowWrapper className={classes.imageWrapper}>
              <Img fixed={data.protheses1.childImageSharp.fixed} />
            </ShadowWrapper>
          </Grid>
          <Grid item sm={12} xs={12}>
            <ul>
              {t('protheses:section3.list2', {
                returnObjects: true,
              }).map(list => (
                <Typography
                  component="li"
                  variant="body2"
                  key={list}
                  dangerouslySetInnerHTML={{ __html: list }}
                />
              ))}
            </ul>
          </Grid>
          <Grid item sm={12} xs={12} container justify="center">
            <ShadowWrapper className={classes.imageWrapper}>
              <Img fixed={data.protheses2.childImageSharp.fixed} />
            </ShadowWrapper>
          </Grid>
          <Grid item sm={12} xs={12}>
            {t('protheses:section3.paragraphs2', {
              returnObjects: true,
            }).map(paragraph => (
              <Typography
                component="p"
                variant="body2"
                paragraph
                key={paragraph}
              >
                {paragraph}
              </Typography>
            ))}
          </Grid>
          <Grid item sm={6} xs={6}>
            <ul>
              {t('protheses:section3.list3', {
                returnObjects: true,
              }).map(list => (
                <Typography
                  component="li"
                  variant="body2"
                  key={list}
                  dangerouslySetInnerHTML={{ __html: list }}
                />
              ))}
            </ul>

            <ShadowWrapper className={classes.imageWrapper}>
              <Img fluid={data.protheses3.childImageSharp.fluid} />
            </ShadowWrapper>
          </Grid>
          <Grid item sm={6} xs={6}>
            <ul>
              {t('protheses:section3.list4', {
                returnObjects: true,
              }).map(list => (
                <Typography
                  component="li"
                  variant="body2"
                  key={list}
                  dangerouslySetInnerHTML={{ __html: list }}
                />
              ))}
            </ul>
            <ShadowWrapper className={classes.imageWrapper}>
              <Img fluid={data.protheses4.childImageSharp.fluid} />
            </ShadowWrapper>
          </Grid>
        </Grid>
      </Section>
    </React.Fragment>
  )
}

ImplantologiePage.defaultProps = {}

export default ImplantologiePage
